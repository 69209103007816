<template>
  <v-container>
    <v-row align="center">
      <v-col
        sm="8"
        md="8"
        lg="6"
        xl="4"
        class="mx-auto"
      >
        <img
          :src="require('../assets/lamiapratica_nero.png')"
          height="30px"
          alt=""
        >
        <v-card
          class="mx-auto pa-4 pa-sm-6 pa-md-16 my-4"
          elevation="6"
          style="max-width: 600px;"
        >
          <v-form
            ref="form"
            class="text-left"
            @submit.prevent="login"
          >
            <v-row>
              <v-col>
                <h2 class="mb-2">
                  Accedi al tuo account
                </h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.email"
                  dense
                  label="Email"
                  type="email"
                  outlined
                  autocomplete="off"
                  :rules="[formRules.required,formRules.emailRules]"
                  @keyup.enter="login"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  class="no-uppercase"
                  small
                  text
                  color="primary"
                  :to="{name:'password-reminder'}"
                >
                  Hai dimenticato la password?
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.password"
                  dense
                  :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                  label="Password"
                  :type="show1 ? 'text' : 'password'"
                  outlined
                  :rules="[formRules.required]"
                  @keyup.enter="login"
                  @click:append="show1 = !show1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  block
                  class="primary darken-1 white--text"
                  :loading="loading"
                  @click="login"
                >
                  Continua
                </v-btn>
                <v-alert
                  v-if="error"
                  dense
                  outlined
                  color="error"
                  class="my-5"
                  dismissible
                >
                  {{ error }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row
              v-if="progress > 0"
              class="mt-3"
            >
              <v-col class="text-center">
                <v-progress-linear
                  v-model="progress"
                  height="20"
                >
                  <strong>{{ Math.ceil(progress) }}%</strong>
                </v-progress-linear>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="my-4">
      <v-col class="text-center">
        <span>Non hai un account? </span>
        <v-btn
          class="no-uppercase"
          text
          color="primary"
          :to="{name:'register'}"
        >
          Registrati
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data: () => ({
    user: {
      email: '',
      password: '',
    },
    error: null,
    loading: false,
    show1: false,
  }),
  computed: {
    ...mapGetters(['formRules', 'progress']),
  },
  methods: {
    login() {
      this.loading = true
      this.error = null

      if (this.$refs.form.validate()) {
        this.user.device_name = 'prova'
        this.$store.dispatch('login', this.user).then(async () => {
          await this.$store.dispatch('reload_all_data')

          setTimeout(async () => {
            await this.$router.push({ name: 'dashboard' })
            // eslint-disable-next-line no-restricted-globals
            location.reload()
            this.$store.commit('SET_PROGRESS', 0)
          }, 500)
        }).catch(e => {
          this.error = e.response.data.message
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped>
.no-uppercase {
    text-transform: unset !important;
}
</style>
